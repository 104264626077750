<template>
    <div>
        <div class="header">
            <van-icon name="arrow-left" @click="$router.push({path: '/self/service'})"/>
            <div class="title" style="font-size:18px;font-weight:550">钱包</div>
            <div class="bill" @click="toBill" style="font-size:18px">账单</div>
        </div>
        <div v-for="item in list" :key="item.id" class="item" @click="toDetail(item.id)" >
            <div class="icon"><img :src="item.icon" alt=""></div> 
            <div class="con" style="font-size:18px">
                <div class="text">{{ item.text }} <span>{{ item.tip }}</span></div>
                <div class="value">{{ item.value }}</div>
                <van-icon name="arrow" color="#787878" />
            </div>
        </div>
        <div v-for="item in payList" :key="item.id" class="pay">
            <div class="icon"><img :src="item.icon" alt=""></div> 
            <div class="con" style="font-size:18px">
                <div class="text">{{ item.text }} <span>{{ item.tip }}</span></div>
                <div class="value">{{ item.value }}</div>
                <van-icon name="arrow" color="#787878" />
            </div>
        </div>
        <div class="bottom">
                <div @click="tosfxx">身份信息</div>
                <div  class="split">|</div>
                <div @click="$router.push({path: '/self/service/zhifus'})">支付设置</div>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex'


export default {
    data() {
        return {
            payList: [
                { id: 0, text: '分付', icon: '/images/pay-fen.png' },
                { id: 1, text: '支付分', icon: '/images/pay-code.png' },
                { id: 2, text: '消费者保护', icon: '/images/consumer-protection.png' },
            ]
        }
    },
    computed: {
        ...mapState({
            fund: state => state.fund
           
        }),
        list() {
            this.fund.coin = this.returnFloat(this.fund.coin)
            this.fund.coinCash = this.returnFloat(this.fund.coinCash)
            return [
                { id: 0, text: '零钱', icon: '/images/cash2.png', value: `¥${ this.fund.coin}` },
                { id: 1, text: '零钱通', icon: '/images/zero-down-pay.png', value: `¥${this.fund.coinCash}`, tip: '收益率1.85%' },
                { id: 2, text: '银行卡', icon: '/images/bank-card.png', value: '' },
                { id: 3, text: '亲属卡', icon: '/images/relatives-card.png', value: '' }
            ]
        }
    },
    methods: {
        toDetail(id) {
         
            if (id==2) {
                this.$router.push({ path: '/self/service/bill/banklist'})
            }
            if (id==0) {
                this.$router.push({ path: '/self/service/chongzhi'})
            }
            if (id==1) {
                this.$router.push({ path: '/self/service/licai'})
            }
        },
        toBill() {
            this.$router.push({ path: '/self/service/bill/billList'})
        },
        tosfxx() {
            this.$router.push({ path: '/self/service/sfxx'})
        },
        returnFloat(num) {
    num = num.toString().replace(/,/g, ""); // 转成字符串类型  如能确定类型 这步可省去
      console.log(num)
      if (num.indexOf(".") !== -1) {
        let [integerPart, decimalPart] = num.split(".");
     
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
          decimalPart += "0";
        }
     
        num = `${integerPart}.${decimalPart}`;
      } else {
        num += ".00";
      }
     
      return num;
    }
    }
}


</script>
<style lang="less" scoped>
.header {
    position: relative;
    z-index: 99;
    // overflow: hidden;
    height: 45px;
    display: flex;
    align-items: center;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #fff;
    opacity: 1;
    color: #000;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    .title {
        flex: 1;
        margin-left: 17px;
    }
    .bill {
        font-weight: 400;
        font-size: 14px;
    }
}
.item {
    background: #fff;
    width: 100%;
    padding-left: 10px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    .icon {
        padding-bottom: 12px;
        img {
            width: 20px;
            height:20px;
            margin-right: 18px;
        }
    }
    .con {
        flex: 1;
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        border-bottom: 1px solid #f2f2f2;
        font-size: 14px;
        .text {
            flex: 1;
            span {
                font-size: 10px;
                font-weight: 500;
                color: #F1B067;
                margin-left: 6px;
            }
        }
        .value {
            padding-right: 8px;
        }
        .van-icon {
            margin-top: 0px;
            margin-right: 10px;
        }
    }
}
.item:nth-of-type(4) {
    .icon {
        img {
            width: 20px;
            height: 20px;
            margin-left: 3px;
            margin-right: 20px;
        }
    }
}
.pay {
    background: #fff;
    width: 100%;
    padding-left: 10px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .icon {
        padding-bottom: 16px;
        img {
            width: 20px;
            height: 20px;
            margin-right: 18px;
        }
    }
    .con {
        flex: 1;
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        border-bottom: 1px solid #f2f2f2;
        .text {
            flex: 1;
            span {
                font-size: 10px;
                font-weight: 500;
                color: #F1B067;
                margin-left: 6px;
            }
        }
        .value {
            padding-right: 8px;
        }
        .van-icon {
            margin-top: -2px;
            margin-right: 10px;
        }
    }
}
.bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    div {
        color: #6a7ba1;
    }
    .split {
        margin-left: 6px;
        margin-right: 6px;
        color: rgb(204, 204, 204);
    }
}
</style>